@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #e9e9e9;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #cfcfcf;
  border-radius: 6px;
  border: 3px solid #e9e9e9;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #bebebe;
}
